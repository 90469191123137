import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { formatPrice } from "./util";

export interface PackageInfo {
  title: string;
  price: number;
  items: Array<{ title: string; subTitle: string; price: number }>;
}

export const packages: Record<string, PackageInfo> = {
  standard: {
    title: "Standard Package",
    price: 1548,
    items: [
      { title: "CCL", subTitle: "Admin", price: 749 },
      { title: "VIN", subTitle: "Verification", price: 400 },
      { title: "Title", subTitle: "Processing", price: 399 },
    ],
  },
  "vin-verification": {
    title: "VIN Verification",
    price: 400,
    items: [{ title: "VIN", subTitle: "Verification", price: 400 }],
  },
  "registration-processing": {
    title: "Registration Processing",
    price: 399,
    items: [
      { title: "Title/Registration", subTitle: "Processing", price: 399 },
    ],
  },
};

export const renderPackageInfo = (packageType: string | undefined) => {
  // Use 'standard' as default if packageType is undefined or not found
  const selectedPackage =
    packageType && packages[packageType] ? packageType : "standard";
  const packageData = packages[selectedPackage];

  return (
    <section className="py-3 text-center" id="package-info">
      <Container fluid="xl">
        <Row>
          <Col xs={12}>
            <h1>{packageData.title}</h1>
            <h3 className="py-2 gold">{formatPrice(packageData.price)}</h3>
          </Col>
        </Row>
        <Row className="mt-3 justify-content-center">
          {packageData.items.map((item, index) => (
            <Col md={4} className="my-1 " key={index}>
              <article>
                <h3>
                  <small>{item.title}</small> {item.subTitle}
                </h3>
                <p>{formatPrice(item.price)}</p>
              </article>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
