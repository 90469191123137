import React from "react";
import { Form } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

interface Option {
  value: string;
  label: string;
}

interface FormSelectProps {
  label: string;
  name: string;
  options: Option[];
}

const FormSelect: React.FC<FormSelectProps> = ({ label, name, options }) => {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Field as="select" name={name} className="form-control">
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component="div" className="text-danger" />
    </Form.Group>
  );
};

export default FormSelect;
