import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Formik, Form as FormikForm, Field } from "formik";
import * as yup from "yup";
import "yup-phone-lite";
import FormInput from "./components/inputBox";
import FormSelect from "./components/formSelect";
import { renderPackageInfo } from "./packageInfo";
import { packages, PackageInfo } from "./packageInfo";
import { formatPrice, luhnCheck } from "./util";
import { config } from "./config";

const validationSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .required("Card number is required")
    .matches(/^[0-9]+$/, "Card number must only contain digits")
    .min(13, "Card number must be at least 13 digits")
    .max(19, "Card number must not exceed 19 digits")
    .test("luhn", "Invalid card number", (value: any) => {
      if (!value) return true; // Let required() handle empty values
      return luhnCheck(value);
    }),
  expDateM: yup.string().required("Exp. Month is required"),
  expDateY: yup.string().required("Exp. Year is required"),
  cvv: yup.string().required("CVV is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .phone("US", "Please enter a valid phone number")
    .required("Phone is required"),
  country: yup.string().required("Country is required"),
  terms: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required(),
});

const initialValues = {
  cardNumber: "",
  expDateM: "",
  expDateY: "",
  cvv: "",
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  email: "",
  phone: "",
  country: "",
  terms: false,
};

const App = () => {
  const [isCompleted, setIsCompleted] = useState(false);

  const { packageType } = useParams<{ packageType?: string }>();

  const [selectedPackage, setSelectedPackage] = useState<
    PackageInfo | undefined
  >(undefined);

  useEffect(() => {
    if (packageType && packages[packageType]) {
      setSelectedPackage(packages[packageType]);
    } else {
      setSelectedPackage(packages.standard);
    }
  }, [packageType]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const response = await fetch(config.apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, amount: selectedPackage?.price }),
      });
      if (response.ok) {
        setIsCompleted(true);
      } else {
        alert("Failed to submit payment");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div id="wrap">
      <header>
        <Container fluid>
          <Row className="py-5">
            <Col xs={12} className="text-center">
              <Image
                src="images/ccl-logo.png"
                alt="Collector Car Leaders"
                id="ccl-logo"
              />
            </Col>
          </Row>
        </Container>
      </header>

      <main id="main">
        {isCompleted ? (
          <section
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "80vh", width: "100vw" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1>Thank you for your payment!</h1>
                  <p>Your transaction has been processed successfully.</p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <>
            {renderPackageInfo(packageType)}

            <section className="py-4">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <FormikForm id="pay-form">
                    <Container fluid="xl">
                      <Row>
                        <Col xs={12}>
                          <h3>Payment Information</h3>
                        </Col>
                        <Col xs={12}>
                          <FormInput
                            label="Card Number:"
                            name="cardNumber"
                            type="text"
                          />
                        </Col>
                        <Col sm={4}>
                          <FormSelect
                            label="Exp. Month"
                            name="expDateM"
                            options={[
                              { value: "", label: "Month" },
                              ...[...Array(12)].map((_, i) => ({
                                value: `${i + 1}`.padStart(2, "0"),
                                label: `${i + 1}`.padStart(2, "0"),
                              })),
                            ]}
                          />
                        </Col>
                        <Col sm={4}>
                          <FormSelect
                            label="Exp. Year"
                            name="expDateY"
                            options={[
                              { value: "", label: "Year" },
                              ...[...Array(14)].map((_, i) => ({
                                value: `${2024 + i}`,
                                label: `${2024 + i}`,
                              })),
                            ]}
                          />
                        </Col>
                        <Col sm={4}>
                          <FormInput label="CVV:" name="cvv" type="text" />
                        </Col>
                        <Col xs={12} className="mt-5">
                          <h3>Billing Information</h3>
                        </Col>
                        <Col sm={6}>
                          <FormInput
                            label="First Name:"
                            name="firstName"
                            type="text"
                          />
                        </Col>
                        <Col sm={6}>
                          <FormInput
                            label="Last Name:"
                            name="lastName"
                            type="text"
                          />
                        </Col>
                        <Col xs={12}>
                          <FormInput
                            label="Street Address:"
                            name="address"
                            type="text"
                          />
                        </Col>
                        <Col md={6}>
                          <FormInput label="City:" name="city" type="text" />
                        </Col>
                        <Col md={3} xs={6}>
                          <FormSelect
                            label="State:"
                            name="state"
                            options={[
                              { value: "", label: "Choose" },
                              { value: "AL", label: "Alabama" },
                              { value: "AK", label: "Alaska" },
                              { value: "AZ", label: "Arizona" },
                              { value: "AR", label: "Arkansas" },
                              { value: "CA", label: "California" },
                              { value: "CO", label: "Colorado" },
                              { value: "CT", label: "Connecticut" },
                              { value: "DE", label: "Delaware" },
                              { value: "DC", label: "District Of Columbia" },
                              { value: "FL", label: "Florida" },
                              { value: "GA", label: "Georgia" },
                              { value: "HI", label: "Hawaii" },
                              { value: "ID", label: "Idaho" },
                              { value: "IL", label: "Illinois" },
                              { value: "IN", label: "Indiana" },
                              { value: "IA", label: "Iowa" },
                              { value: "KS", label: "Kansas" },
                              { value: "KY", label: "Kentucky" },
                              { value: "LA", label: "Louisiana" },
                              { value: "ME", label: "Maine" },
                              { value: "MD", label: "Maryland" },
                              { value: "MA", label: "Massachusetts" },
                              { value: "MI", label: "Michigan" },
                              { value: "MN", label: "Minnesota" },
                              { value: "MS", label: "Mississippi" },
                              { value: "MO", label: "Missouri" },
                              { value: "MT", label: "Montana" },
                              { value: "NE", label: "Nebraska" },
                              { value: "NV", label: "Nevada" },
                              { value: "NH", label: "New Hampshire" },
                              { value: "NJ", label: "New Jersey" },
                              { value: "NM", label: "New Mexico" },
                              { value: "NY", label: "New York" },
                              { value: "NC", label: "North Carolina" },
                              { value: "ND", label: "North Dakota" },
                              { value: "OH", label: "Ohio" },
                              { value: "OK", label: "Oklahoma" },
                              { value: "OR", label: "Oregon" },
                              { value: "PA", label: "Pennsylvania" },
                              { value: "RI", label: "Rhode Island" },
                              { value: "SC", label: "South Carolina" },
                              { value: "SD", label: "South Dakota" },
                              { value: "TN", label: "Tennessee" },
                              { value: "TX", label: "Texas" },
                              { value: "UT", label: "Utah" },
                              { value: "VT", label: "Vermont" },
                              { value: "VA", label: "Virginia" },
                              { value: "WA", label: "Washington" },
                              { value: "WV", label: "West Virginia" },
                              { value: "WI", label: "Wisconsin" },
                              { value: "WY", label: "Wyoming" },
                            ]}
                          />
                        </Col>
                        <Col md={3} xs={6}>
                          {/* <Form.Group controlId="zip">
                        <Form.Label>Zip:</Form.Label>
                        <Form.Control type="text" name="zip" />
                      </Form.Group> */}
                          <FormInput label="Zip:" name="zip" type="text" />
                        </Col>
                        <Col md={6}>
                          {/* <Form.Group controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" name="email" />
                      </Form.Group> */}
                          <FormInput label="Email:" name="email" type="email" />
                        </Col>
                        <Col md={6}>
                          {/* <Form.Group controlId="phone">
                        <Form.Label>Phone:</Form.Label>
                        <Form.Control type="text" name="phone" />
                      </Form.Group> */}
                          <FormInput label="Phone:" name="phone" type="text" />
                        </Col>
                        <Col xs={12}>
                          {/* <Form.Group controlId="country">
                        <Form.Label>Country:</Form.Label>
                        <Form.Control as="select" name="country">
                          <option value="USA">USA</option>
                          <option value="Canada">Canada</option>
                          <option value="Other">Other</option>
                        </Form.Control>
                      </Form.Group> */}
                          <FormSelect
                            label="Country:"
                            name="country"
                            options={[
                              { value: "", label: "Choose" },
                              { value: "USA", label: "USA" },
                              { value: "Canada", label: "Canada" },
                              { value: "Other", label: "Other" },
                            ]}
                          />
                        </Col>
                        <div className="col-12 mt-3 text-center">
                          {/* <label>
                        <input
                          type="checkbox"
                          value="I have read and agree to the terms and conditions."
                          name="Terms"
                        />
                        I have read and agree to the terms and conditions.
                      </label> */}
                          <Field name="terms">
                            {({ field, meta }: { field: any; meta: any }) => (
                              <div>
                                <label>
                                  <input type="checkbox" {...field} /> I
                                  authorize the Trailer Solutions - FL DBA: CCL
                                  to charge the credit card provided in this
                                  online payment portal according to the terms
                                  outlined. This payment authorization is for
                                  the goods/services described and the specified
                                  amount, valid for one-time use only. I certify
                                  that I am an authorized user of this credit
                                  card and will not dispute the payment with my
                                  credit card company, provided the transaction
                                  corresponds to the terms indicated.
                                </label>
                                {meta.touched && meta.error && (
                                  <div className="text-danger">
                                    {meta.error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                        </div>
                        <Col xs={12} className="text-center">
                          <h3>
                            Pay {formatPrice(selectedPackage?.price || 0)}
                          </h3>
                        </Col>
                        <Col xs={12} className="mt-3 text-center">
                          <Button
                            type="submit"
                            id="btnSubmit"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <i
                                className="fas fa-spinner fa-spin"
                                id="ajax-loader"
                              ></i>
                            ) : null}
                            Pay Now
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </FormikForm>
                )}
              </Formik>
            </section>
          </>
        )}
      </main>

      <footer>
        <div className="container-fluid text-center">
          <div className="row" id="copyright">
            <div className="col-12">
              <p>&copy; 2024 Collector Car Leaders. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
