import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage, useField } from "formik";

interface FormInputProps {
  label: string;
  name: string;
  type?: string;
  as?: React.ElementType;
  children?: React.ReactNode;
}

const FormInput: React.FC<FormInputProps> = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group controlId={props.name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        {...field}
        {...props}
        isInvalid={meta.touched && !!meta.error}
      >
        {children}
      </Form.Control>
      <ErrorMessage name={props.name} component="div" className="text-danger" />
    </Form.Group>
  );
};

export default FormInput;
